import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { MapPinIcon, UserGroupIcon, AtSymbolIcon, CameraIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
// import { FacebookProvider, Page, MessageUs } from 'react-facebook';

function Privacy() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: 'Home', href: '/'}
  ];

  return (
    <>
    <main>
      <div className="bg-gray-900">
        <header className="absolute inset-x-0 top-0 z-50">
          <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
            <div className="flex lg:flex-1">
              {/* <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  className="h-8 w-auto"
                />
              </a> */}
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                  {item.name}
                </a>
              ))}
            </div>
            {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <a href="#" className="text-sm font-semibold leading-6 text-white">
                Log in <span aria-hidden="true">&rarr;</span>
              </a>
            </div> */}
          </nav>
          <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
            <div className="fixed inset-0 z-50" />
            <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
              <div className="flex items-start justify-between">
                <span className="-m-3 p-1.5">
                  <span className="sr-only">Forsaken Souls Haunted Attraction</span>
                  <a href="/" onClick={() => setMobileMenuOpen(false)}><img
                    alt=""
                    src="/images/forsakensouls-transparent.png"
                    className="h-24 w-auto -ml-2"
                  /></a>
                </span>
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(false)}
                  className="-m-2.5 rounded-md p-2.5 text-gray-400"
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/25">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={() => setMobileMenuOpen(false)}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </Dialog>
        </header>

        <div className="relative isolate">
        {/* <img
            alt=""
            src="/images/family.png"
            className="absolute inset-0 -z-10 h-full w-full object-cover blur-sm"
          /> */}
          <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
          >
            
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            />
            
          </div>
          
          <div className="mx-auto max-w-2xl pt-8 sm:pt-16 lg:pt32">
            {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-400 ring-1 ring-white/10 hover:ring-white/20">
                Announcing our next round of funding.{' '}
                <a href="#" className="font-semibold text-white">
                  <span aria-hidden="true" className="absolute inset-0" />
                  Read more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div> */}
                          <center><a href="/"><img src="/images/forsakensouls-transparent.png" alt="Forsaken Souls Haunted Attraction" /></a></center>

            <div className="text-white flex flex-col gap-y-6 px-8">

              <h1 class="privacy-policy-h1 text-xl">Privacy Policy
<br /><span>Last Updated On 12-Aug-2024</span>
<br /><span>Effective Date 12-Aug-2024</span>
</h1>

<p class="privacy-policy-p">
    This Privacy Policy describes the policies of

    Forsaken Souls Haunted Attraction,
    290 S 6th Street,
    Wisconsin
    53536,
    United States of America (the),
    email: info@forsakensouls.com

    on the collection,
    use and disclosure of your information that we collect
    when you use our website ( https://www.forsakensouls.com ).
    (the “Service”). By accessing or using
    the
    Service, you are consenting to the collection, use and
    disclosure of your information in accordance with this
    Privacy Policy. If you do not consent to the same,
    please do not access or use the Service.
</p>

<p class="privacy-policy-p">
    We may modify this Privacy Policy at any time without
    any prior notice to you and will post the revised
    Privacy Policy on the Service. The revised Policy will
    be effective 180 days from when the
    revised Policy is posted in the Service and your
    continued access or use of the Service after such time
    will constitute your acceptance of the revised Privacy
    Policy. We therefore recommend that you periodically
    review this page.
</p>

<ol class="privacy-policy-ol" className="flex flex-col gap-y-6">
    
    

    
    

    
    <li className="flex flex-col gap-y-6">
        <h2 className="text-lg" class="privacy-policy-h2">
            Your Rights:
        </h2>
        <p class="privacy-policy-p">
            Depending on the law that applies, you may have
            a right to access and rectify or erase your
            personal data or receive a copy of your personal
            data, restrict or object to the active
            processing of your data, ask us to share (port)
            your personal information to another entity,
            withdraw any consent you provided to us to
            process your data, a right to lodge a complaint
            with a statutory authority and such other rights
            as may be relevant under applicable laws. To
            exercise these rights, you can write to us at
            info@forsakensouls.com.
            We will respond to your
            request in accordance with applicable law.
        </p>

        
        <p class="privacy-policy-p">
            Do note that if you do not allow us to collect
            or process the required personal information or
            withdraw the consent to process the same for the
            required purposes, you may not be able to access
            or use the services for which your information
            was sought.
        </p>
    </li>
        <li className="flex flex-col gap-y-6">
        <h2 className="text-lg" class="privacy-policy-h2">
            Cookies Etc.
        </h2>
        <p class="privacy-policy-p">

            
            Forsaken Souls Haunted Attraction does not use cookies.
        </p>
    </li>
    
    <li className="flex flex-col gap-y-6">
        <h2 className="text-lg" class="privacy-policy-h2">
            Security:
        </h2>
        <p class="privacy-policy-p">
            The security of your information is important to
            us and we will use reasonable security measures
            to prevent the loss, misuse or unauthorized
            alteration of your information under our
            control. However, given the inherent risks, we
            cannot guarantee absolute security and
            consequently, we cannot ensure or warrant the
            security of any information you transmit to us
            and you do so at your own risk.
        </p>
    </li>

        <li className="flex flex-col gap-y-6">
        <h2 className="text-lg" class="privacy-policy-h2">
            Third Party Links & Use Of Your Information:
        </h2>
        <p class="privacy-policy-p">
            Our Service may contain links to other websites
            that are not operated by us. This Privacy Policy
            does not address the privacy policy and other
            practices of any third parties, including any
            third party operating any website or service
            that may be accessible via a link on the
            Service. We strongly advise you to review the
            privacy policy of every site you visit. We have
            no control over and assume no responsibility for
            the content, privacy policies or practices of
            any third party sites or services.
        </p>
    </li>
    
    <li className="flex flex-col gap-y-6">
        <h2 className="text-lg" class="privacy-policy-h2">
            Grievance / Data Protection Officer:
        </h2>
        <p class="privacy-policy-p">
            If you have any queries or concerns about the
            processing of your information that is available
            with us, you may email our Grievance Officer at
            Forsaken Souls Haunted Attraction,
            290 S 6th Street,
            email: info@forsakensouls.com.
            We will address your concerns in accordance with applicable law.
        </p>
    </li>
</ol>
              
              {/* <div className="mt-10 flex items-center justify-center gap-x-6">
                <a
                  href="#"
                  className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                >
                  Get started
                </a>
                <a href="#" className="text-sm font-semibold leading-6 text-white">
                  Learn more <span aria-hidden="true">→</span>
                </a>
              </div> */}
            </div>
            
          </div>
          
          <div className="pt-16 pb-12 text-center text-gray-400 px-8">
            &copy; 2024 Forsaken Souls Haunted Attraction, All Rights Reserved. &#x2022; <a href="/privacy" className="text-indigo-400 hover:text-indigo-300">Privacy Policy</a>
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-50rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-50rem)]"
          >
            <div
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            />
          </div>
        </div>
      </div>
    </main>
    </>
    
  );
}

export default Privacy;
